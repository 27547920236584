/* Contact.css */

.contact-container {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;
  animation: fadeIn 0.8s ease-in-out both;
}

.contact-info {
  margin-bottom: 40px;
}

.contact-heading {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.contact-description {
  font-size: 1.1rem;
  color: #cccccc;
}

.contact-details {
  margin-top: 20px;
}

.contact-detail {
  margin-bottom: 20px;
}

.detail-title {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #ffffff;
}

.detail-text {
  font-size: 1rem;
  color: #cccccc;
}

.contact-link {
  color: #ffffff;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.contact-form {
  background-color: #333333;
  padding: 20px;
  border-radius: 8px;
}

.form-heading {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.form-label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #ffffff;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  background-color: #444444;
  color: #ffffff;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border: 1px solid #ffffff;
}

.form-button {
  background-color: #555555;

  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.form-button:hover {
  background-color: #666666;
}

.form-success {
  color: #00ff00;
  font-size: 1rem;
  margin-top: 10px;
}

.form-error {
  color: #ff0000;
  font-size: 1rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .contact-container {
    padding-top: 100px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}