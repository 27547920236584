/* Projects Styles */
.projects-container {
    padding: 60px 20px;
    background-color: #000;
    color: #fff;
    text-align: center;
    animation: fadeIn 0.8s ease-in-out both;
}

.projects-intro {
    margin-bottom: 40px;
}

.projects-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.projects-description {
    font-size: 1.2rem;
}

/* Project Cards */
.projects-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards on larger screens */
    justify-content: center;
}

.project-card {
    position: relative;
    width: 300px;
    height: 200px;
    perspective: 1000px;
    animation: fadeIn 0.8s ease-in-out both;
}

.project-card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.project-card:hover .project-card-inner {
    transform: rotateY(180deg);
}

.project-card-front,
.project-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.project-card-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #222;
    color: #fff;
}

.project-card-back {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333;
    color: #fff;
    transform: rotateY(180deg);
}

.project-image {
    width: 100%;
    height: 80%;
    border-bottom: 2px solid #444;
}

.project-title {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}

.project-details {
    padding: 20px;
    font-size: 1rem;
    text-align: center;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .projects-container{
padding-top: 100px;
    }
    .projects-list {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center cards horizontally */
        gap: 20px; /* Space between vertically stacked cards */
    }

    .project-card {
        width: 90%; /* Adjust width to fit smaller screens */
        max-width: 500px; /* Ensure cards are not too large */
        margin: 0 auto; /* Center cards horizontally */
    }

    .project-image {
        width: 100%; /* Ensure image scales correctly */
    }

    .project-title {
        font-size: 1.2rem; /* Adjust font size for better readability */
    }

    .project-details {
        font-size: 0.9rem; /* Adjust font size for better readability */
    }
}
.project-card:nth-child(1) {
    animation-delay: 0.2s;
}

.project-card:nth-child(2) {
    animation-delay: 0.4s;
}

.project-card:nth-child(3) {
    animation-delay: 0.6s;
}

@media (max-width: 480px) {
    .project-title {
        font-size: 1rem; /* Further reduce font size for very small screens */
    }

    .project-details {
        font-size: 0.8rem; /* Further reduce font size for very small screens */
    }

    .projects-list {
        /* Continue using column layout, already defined above */
        gap: 20px; /* Consistent spacing between cards */
    }

    .project-card {
        width: 100%; /* Full width for very small screens */
        max-width: none; /* Remove max-width constraint */
        margin-bottom: 20px; /* Ensure proper spacing between cards */
    }
}
