.background-container {
    position: absolute;
    align-self: center;
    background-size: cover; /* Cover the section with the image */
    background-position: center; /* Center the image */
    opacity: 0.3; /* Adjust opacity as needed */
    z-index: 10; /* Ensure it's behind the testimonials content */
    pointer-events: none; /* Prevent interaction with the background */
  }
  
  @media screen and (max-width:768px){
    .background-container{
      margin-top: 20%;
      min-height: 800px;
    }
  }