/* Navbar Styles */
.navbar {
    backdrop-filter: blur(10px); /* Strong blur effect */
    background: rgba(255, 255, 255, 0.1) !important; /* Semi-transparent white background */
}

.navbar-logo {
    font-size: 1.8rem;
    font-weight: bold;
}

.navbar-nav {
    text-align: center; /* Center items on mobile */
}

.navbar-item {
    font-size: 1rem;
    transition: color 0.3s;
}

.navbar-item:hover {
    color: #ccc;
}

/* Toggle Button Styles */
.navbar-toggler {
    border: none; /* Remove default border */
    background: transparent; /* Ensure background is transparent */
}

.navbar-toggler-icon {
    color: #fff; /* White color for FontAwesome icon */
    font-size: 1.5rem; /* Adjust size as needed */
}

/* Responsive Design - Adjustments for mobile */
@media (max-width: 768px) {
    .navbar-nav {
        flex-direction: column;
        margin-top: 1rem;
        width: 100vw !important;
    }

    .navbar-nav .nav-link {
        padding: 0.5rem 1rem;
        text-align: center;
        /* Optional: Divider between items */
    }

    .navbar-nav .nav-link:last-child {
        border-bottom: none; /* Remove divider from last item */
    }
}
