/* About.css */

.about-container {
    font-family: Arial, sans-serif;
    color: #fff; /* Text color changed to white */
    background-color: #000; /* Background color changed to black */
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about-heading, .mission-heading, .values-heading {
    color: #fff; /* Heading color changed to white */
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: bold;
    animation: fadeIn 1s ease-out;
  }
  
  .about-description {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 40px;
    line-height: 1.6;
    animation: fadeIn 1s ease-out;
  }
  
  .mission-section, .values-section {
    margin-bottom: 40px;
  }
  
  .mission-text {
    text-align: center;
    font-size: 1.1em;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    animation: fadeIn 1s ease-out;
  }
  
  .values-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .value-item {
    background-color: #1e1e1e; /* Dark gray background for contrast */
    border: 1px solid #333; /* Slightly lighter border */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); /* Light shadow for better visibility */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: slideIn 0.5s ease-out;
  }
  
  .value-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(255, 255, 255, 0.3); /* Enhanced shadow on hover */
  }
  
  .value-title {
    color: #fff; /* White text color for titles */
    font-size: 1.5em;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .value-text {
    color: #ccc; /* Light gray text color for better readability */
    font-size: 1.1em;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (max-width: 768px) {
    .about-container{
      padding-top: 100px;
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  