.b-down{
    padding-top: 60px;
    background-color: black;
}
* {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  @media screen and (max-width:768px) {
    .b-down{
        padding-top: 0px;
    }
  }