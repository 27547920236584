.services-container {
    color: #fff;
    background-color: #000;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
}

/* Intro Section */
.services-intro {
    text-align: center;
    margin-bottom: 40px;
}

.services-heading {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-out;
}

.services-description {
    font-size: 1.2rem;
    animation: fadeInUp 1.5s ease-out;
}

/* Services List */
.services-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.service-card {
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, box-shadow 0.3s;
    animation: fadeIn 2s ease-in-out;
}

.service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

.service-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.service-details {
    font-size: 1rem;
}

/* Call to Action Section */
.cta-section {
    text-align: center;
    margin-top: 40px;
}

.cta-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    animation: fadeInUp 2s ease-out;
}

.cta-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
    animation: fadeInUp 2.5s ease-out;
}

.cta-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
}

.cta-button:hover {
    transform: scale(1.05);
    background-color: #ddd;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries */
@media (max-width: 768px) {
    .services-container{
        padding-top: 100px;
    }
    .services-heading {
        font-size: 2.5rem;
    }

    .services-description {
        font-size: 1rem;
    }

    .service-title {
        font-size: 1.2rem;
    }

    .service-details {
        font-size: 0.9rem;
    }

    .cta-heading {
        font-size: 2rem;
    }

    .cta-text {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .services-heading {
        font-size: 2rem;
    }

    .services-description {
        font-size: 0.9rem;
    }

    .service-card {
        width: 100%;
    }

    .service-title {
        font-size: 1rem;
    }

    .service-details {
        font-size: 0.8rem;
    }

    .cta-heading {
        font-size: 1.8rem;
    }

    .cta-text {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
    }
}