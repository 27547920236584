/* Footer Styles */
.footer {
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-logo {
  /* Placeholder for the logo */
  width: 150px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  background: url('../img/logo/logo-sharp.png');
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: cover;
}

.footer-info {
  font-size: 1rem;
}

.footer-address {
  align-items: center;
  font-size: 0.9rem;
}

.footer-address a {
  color: #fff;
  text-decoration: none;
}

.footer-address a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
      padding: 15px;
  }

  .footer-logo {
      width: 120px; /* Adjust size for smaller screens */
      height: 40px; /* Adjust size for smaller screens */
  } 

  .footer-info,
  .footer-address {
      font-size: 0.9rem; /* Adjust font size for better readability */
  }
}

@media (max-width: 480px) {
  .footer {
      padding: 10px;
  }

  .footer-logo {
      width: 120px; /* Adjust size for very small screens */
      height: 40px; /* Adjust size for very small screens */
  }

  .footer-info,
  .footer-address {
      font-size: 0.8rem; /* Further adjust font size for very small screens */
  }
}
