.custom-cursor {
    position: fixed;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255); /* The color doesn't matter much due to blending */
    border-radius: 50%;
    pointer-events: none; /* Prevents the cursor from interfering with clicks */
    transform: translate(-50%, -50%); /* Center the dot on the cursor */
    z-index: 9999; /* Ensure the cursor is on top of other elements */
    mix-blend-mode:difference; /* Inverts the colors beneath the dot */
  }
@media screen and (max-width: 768px) {
    .custom-cursor {
        display: none;
    }
}