.home-container {
    color: #fff;
    background-color: #000;
    font-family: 'Arial', sans-serif;
}

/* Hero Section */
.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: linear-gradient(135deg, #000, #333);
    padding: 0 20px;
}

.hero-content {
    max-width: 800px;
}

.hero-text {
    font-size: 4rem;
    margin-bottom: 20px;
    font-weight: bold;
    animation: fadeInUp 1s ease-out;
}

.hero-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    animation: fadeInUp 1s ease-out 0.5s;
}

.hero-button:hover {
    background-color: #555;
    transform: scale(1.05);
}

/* Introduction Section */
.intro-section {
    padding: 60px 20px;
    text-align: center;
    background: #111;
}

.intro-text {
    font-size: 1.5rem;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-out;
}

.highlights {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    animation: fadeInUp 1s ease-out 0.5s;
}

.highlight {
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
    width: 100%;
    max-width: 500px;
    font-size: 1.2rem;
}

/* Features Section */
.features-section {
    padding: 60px 20px;
    text-align: center;
    background: #222;
}

.features-heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-out;
}

.features-list {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
    gap: 20px;
    justify-content: center; /* Center items */
    animation: fadeInUp 1s ease-out 0.5s;
}

.feature {
    background-color: #333;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
    width: 100%;
    max-width: 350px;
    text-align: center; /* Center text */
}

.feature h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.feature p {
    font-size: 1.1rem;
}

.center-component {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Testimonials Section */
.testimonials-section {
    position: relative; 
    padding: 60px 20px;
    text-align: center;
    overflow: hidden; 
}

.testimonials-heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-out;
}

.testimonials {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    animation: fadeInUp 1s ease-out 0.5s;
}

.testimonial {
    background-color: #333;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
    width: 100%;
    max-width: 600px;
    text-align: center; /* Center text */
}

.testimonial p {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.testimonial span {
    font-size: 1rem;
    color: #ccc;
}

/* Call to Action Section */
.cta-section {
    padding: 60px 20px;
    text-align: center;
    background-color: #111;
}

.cta-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-out;
}

.cta-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-out 0.5s;
}

.cta-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    animation: fadeInUp 1s ease-out 0.5s;
}

.cta-button:hover {
    background-color: #555;
    transform: scale(1.05);
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries */
@media (max-width: 768px) {
    .hero-text {
        font-size: 3rem;
    }

    .hero-button {
        font-size: 1rem;
    }
    .hero-section{
        flex-direction: column-reverse;
    }

    .intro-text {
        font-size: 1.2rem;
    }

    .features-heading,
    .testimonials-heading,
    .cta-heading {
        font-size: 2rem;
    }

    .feature,
    .testimonial {
        padding: 20px;
    }

    .feature h3,
    .testimonial p {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .hero-text {
        font-size: 2.5rem;
    }

    .hero-button {
        font-size: 0.9rem;
    }

    .intro-text {
        font-size: 1rem;
    }

    .features-heading,
    .testimonials-heading,
    .cta-heading {
        font-size: 1.8rem;
    }

    .feature,
    .testimonial {
        padding: 15px;
    }

    
    .testimonial p {
        font-size: 0.9rem;
    }

    .cta-button {
        font-size: 0.9rem;
    }
    .feature h3{
        font-size: 1.2rem;
    }
}

.text-color-red {
    color: red;
}
